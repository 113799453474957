/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'strategy-legend': {
    width: 20,
    height: 11,
    viewBox: '0 0 20 11',
    data: '<path pid="0" _stroke="#353535" d="M0 5h20"/>'
  }
})
