/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'q1': {
    width: 18,
    height: 18,
    viewBox: '0 0 18 18',
    data: '<path pid="0" d="M17.314 9H1M9.157.843v16.314" _stroke="#fff" stroke-linecap="round"/><circle pid="1" cx="4.5" cy="4.5" r="2.5" _fill="#fff"/>'
  }
})
