/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'checked': {
    width: 19,
    height: 18,
    viewBox: '0 0 19 18',
    data: '<path pid="0" _fill="none" _stroke="#565656" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 8.5L9 13l5.5-7.5"/>'
  }
})
