/* eslint-disable */
require('./arrow-down')
require('./arrow-right')
require('./arrow-up')
require('./bubbleSize')
require('./checked')
require('./close-modal')
require('./current-location')
require('./dots-size')
require('./expected')
require('./info')
require('./q1')
require('./q2')
require('./q3')
require('./q4')
require('./search')
require('./sort')
require('./strategy-legend')
require('./strategy')
require('./table')
require('./tenyr')
require('./y-axes')
