/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'q2': {
    width: 18,
    height: 18,
    viewBox: '0 0 18 18',
    data: '<path pid="0" d="M17.314 9H1m8.157 8.157V.843" _stroke="#fff" stroke-linecap="round"/><circle pid="1" r="2.5" transform="matrix(1 0 0 -1 4.5 13.5)" _fill="#fff"/>'
  }
})
