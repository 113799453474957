/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'q3': {
    width: 18,
    height: 18,
    viewBox: '0 0 18 18',
    data: '<path pid="0" d="M1 9h16.314m-8.157 8.157V.843" _stroke="#fff" stroke-linecap="round"/><circle pid="1" cx="13.814" cy="13.5" r="2.5" transform="rotate(-180 13.814 13.5)" _fill="#fff"/>'
  }
})
