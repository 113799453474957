/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'tenyr': {
    width: 8,
    height: 6,
    viewBox: '0 0 8 6',
    data: '<path pid="0" d="M.69 4.471h6.618L4 .69.691 4.47zm-.519-.454L3.48.236a.69.69 0 011.039 0l3.309 3.78a.69.69 0 01-.52 1.145H.691a.69.69 0 01-.52-1.144z" _fill="#007FFF" fill-opacity=".7"/>'
  }
})
