const state = {
  industryOverviewY: 1,
  industryOverviewDotSizeValue: 0,
};

const getters = {
  industryOverviewY: state => state.industryOverviewY,
  industryOverviewDotSizeValue: state => state.industryOverviewDotSizeValue,
};

const mutations = {
  setIndustryOverviewY(state, value) {
    state.industryOverviewY = value;
  },
  setIndustryOverviewDotSizeValue(state, value) {
    state.industryOverviewDotSizeValue = value;
  },
};

const actions = {
  setIndustryOverviewY({ commit }, value) {
    commit("setIndustryOverviewY", value);
  },
  setIndustryOverviewDotSizeValue({ commit }, value) {
    commit("setIndustryOverviewDotSizeValue", value);
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
