/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'bubbleSize': {
    width: 19,
    height: 17,
    viewBox: '0 0 19 17',
    data: '<circle pid="0" cx="4.776" cy="8.717" r="3.457" transform="rotate(-90 4.776 8.717)" _stroke="#353535" stroke-linecap="round"/><circle pid="1" cx="14.689" cy="8.717" r="3.957" transform="rotate(-90 14.689 8.717)" _fill="#353535"/>'
  }
})
