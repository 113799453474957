/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'arrow-right': {
    width: 7,
    height: 11,
    viewBox: '0 0 7 11',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M6.313 5.447l-4.688 4.687-.656-.656L5 5.447.969 1.416l.656-.657 4.688 4.688z" _fill="#20558A"/>'
  }
})
