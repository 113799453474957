const state = {
    sankeyHoverBranch: null
};

const getters = {
    sankeyHoverBranch: state => state.sankeyHoverBranch
};

const mutations = {
    setSankeyHoverBranch(state, value) {
        state.sankeyHoverBranch = value
    }
};

const actions = {
    setSankeyHoverBranch({ commit }, value) {
        commit("setSankeyHoverBranch", value)
    }
};

export default {
    state,
    getters,
    actions,
    mutations
};
