import axios from "axios";
import {
  baseDomain,
  partBMSAEndpoint,
  partBIndustryEndpoint,
  partBSectors,
  getCityReportEndpoint,
} from "../../api/brookingsApi";

const INDUSTRY_TRAITS = ["good_jobs_high", "tradable", "low_presence"];

const state = {
  MSAList: null,
  MSAListIsLoading: false,
  currentMSA: null,
  industryOverview: null,
  industryOverviewIsLoading: false,
  industryWithPriorityTraits: null,
  industryOverviewStats: {
    good_jobs_high: 0,
    tradable: 0,
    low_presence: 0,
    shared: 0,
  },
  naicsListForStrategy: [],
  sectorsByNaics: [],
  sectorsAreLoading: false,
  selectedPreviewSector: null,
  selectedSectorsPartB: [],
  selectedToggles: ["good_jobs_high", "tradable", "low_presence"],
  overedIndustry: null,
  overedIndustryTable: null,
  cityOutlookReportUrl: "",
};

const getters = {
  MSAList: state => state.MSAList,
  MSAListIsLoading: state => state.MSAListIsLoading,
  getCurrentMSA: state => state.currentMSA,
  industryOverview: state => state.industryOverview,
  industryOverviewIsLoading: state => state.industryOverviewIsLoading,
  industryOverviewStats: state => state.industryOverviewStats,
  industryWithPriorityTraits: state => state.industryWithPriorityTraits,
  sectorsWithPriorityIndustry: state => {
    return state.industryOverview.reduce((prev, value) => {
      if (!INDUSTRY_TRAITS.every(t => Number(value[t]) === 1)) {
        return prev;
      }

      const sector = state.sectorsByNaics.find(
        s => Number(s.sector_code) === Number(value.sector_code)
      );

      if (
        !sector ||
        prev.find(ps => Number(ps.sector_code) === Number(sector.sector_code))
      ) {
        return prev;
      }

      return [...prev, sector];
    }, []);
  },
  naicsListForStrategy: state => state.naicsListForStrategy,
  sectorsByNaics: state => state.sectorsByNaics,
  sectorsLoader: state => state.sectorsAreLoading,
  selectedPreviewSector: state => state.selectedPreviewSector,
  selectedSectorsPartB: state => state.selectedSectorsPartB,
  selectedToggles: state => state.selectedToggles,
  overedIndustry: state => state.overedIndustry,
  overedIndustryTable: state => state.overedIndustryTable,
  cityOutlookReportUrl: state => state.cityOutlookReportUrl,
};

const mutations = {
  setMSAList(state, value) {
    state.MSAList = value;
  },
  setMSAListIsLoading(state, value) {
    state.MSAListIsLoading = value;
  },
  setCurrentMSA(state, value) {
    state.currentMSA = value;
  },
  setIndustryOverview(state, value) {
    state.industryOverview = value;
  },
  setIndustryOverviewLoader(state, value) {
    state.industryOverviewIsLoading = value;
  },
  setIndustryWithPriorityTraits(state, industries) {
    let filtered = industries.filter(industry =>
      INDUSTRY_TRAITS.every(t => Number(industry[t]) === 1)
    );

    state.industryWithPriorityTraits = filtered.length ? filtered : null;
  },
  setIndustryOverviewStats(state, industries) {
    const traits = Object.keys(state.industryOverviewStats);
    const uniqueTraits = traits.filter(t => t !== "shared");

    industries.forEach(industry => {
      traits.forEach(t => {
        if (
          t === "shared" &&
          uniqueTraits.every(t => Number(industry[t]) === 1)
        ) {
          state.industryOverviewStats.shared =
            state.industryOverviewStats.shared + 1;
        } else if (Number(industry[t]) === 1) {
          state.industryOverviewStats[t] = state.industryOverviewStats[t] + 1;
        }
      });
    });
  },

  setNaicsListForStrategy(state, value) {
    if (state.naicsListForStrategy.includes(value)) {
      const index = state.naicsListForStrategy.indexOf(value);
      state.naicsListForStrategy.splice(index, 1);
    } else {
      state.naicsListForStrategy.push(value);
      /* This imposes a 10 industry limit. We are removing it
      if (state.naicsListForStrategy.length < 10) {
        state.naicsListForStrategy.push(value);
      }
      */
    }
  },

  replaceNaicsListForStrategy(state, value) {
    state.naicsListForStrategy = value;
  },

  resetIndustryOverviewStats(state) {
    state.industryOverviewStats = {
      good_jobs_high: 0,
      tradable: 0,
      low_presence: 0,
      shared: 0,
    };
  },

  resetNaicsListForStrategy(state) {
    state.naicsListForStrategy = [];
  },
  setSectorsByNaics(state, value) {
    state.sectorsByNaics = value;
  },
  setCityOutlookReportUrl(state, value) {
    state.cityOutlookReportUrl = value;
  },
  setSectorsLoader(state, value) {
    state.sectorsAreLoading = value;
  },
  setSelectedPreviewSector(state, value) {
    state.selectedPreviewSector = value;
  },
  setselectedSectorsPartB(state, value) {
    const existing = state.selectedSectorsPartB.find(
      s => value.sector_code === s.sector_code
    );
    if (existing) {
      const index = state.selectedSectorsPartB.indexOf(existing);
      state.selectedSectorsPartB.splice(index, 1);
    } else {
      state.selectedSectorsPartB.push(value);
    }
  },
  replaceSelectedSectorsPartB(state, value) {
    state.selectedSectorsPartB = [...value];
  },
  resetSectorsPartB(state) {
    state.selectedSectorsPartB = [];
  },
  setToggles(state, value) {
    if (state.selectedToggles.includes(value)) {
      const index = state.selectedToggles.indexOf(value);
      state.selectedToggles.splice(index, 1);
    } else {
      state.selectedToggles.push(value);
    }
  },
  replaceToggles(state, value) {
    console.log({ setSelectedToggles: value });
    state.selectedToggles = value;
  },
  setAllToggles(state) {
    state.selectedToggles = ["good_jobs_high", "tradable", "low_presence"];
  },
  resetToggles(state) {
    state.selectedToggles = [];
  },
  setHoveredIndustry(state, value) {
    state.overedIndustry = value;
  },
  setHoveredIndustryTable(state, value) {
    state.overedIndustryTable = value;
  },
};

const actions = {
  async setMSAList({ commit }) {
    commit("setMSAListIsLoading", true);
    let MSAList = await axios.get(baseDomain + partBMSAEndpoint);
    commit("setMSAList", MSAList.data);
    commit("setMSAListIsLoading", false);
  },
  async setSectorsByNaics({ commit }, msa) {
    commit("setSectorsLoader", true);
    let sectorList = await axios.get(baseDomain + partBSectors + msa);
    commit("setSectorsByNaics", sectorList.data);
    commit("setSectorsLoader", false);
  },
  async setCityOutlookReportUrl({ commit }, city) {
    commit("setCityOutlookReportUrl", "");
    try {
      const reportUrl = getCityReportEndpoint(city);
      await axios.head(reportUrl);
      commit("setCityOutlookReportUrl", reportUrl);
    } catch (err) {
      // File does not exist
      commit("setCityOutlookReportUrl", "");
    }
  },
  async setIndustryOverview({ commit }, msa) {
    commit("setIndustryOverviewLoader", true);
    let industryList = await axios.get(
      baseDomain + partBIndustryEndpoint + msa
    );
    commit("setIndustryOverview", industryList.data);
    commit("setIndustryWithPriorityTraits", industryList.data);
    commit("setIndustryOverviewStats", industryList.data);
    commit("setIndustryOverviewLoader", false);
  },
  resetIndustryOverview({ commit }) {
    commit("setIndustryOverview", null);
  },

  resetIndustryOverviewStats({ commit }) {
    commit("resetIndustryOverviewStats");
  },

  setCurrentMSA({ commit }, value) {
    commit("setCurrentMSA", value);
  },
  async replaceNaicsListForStrategy({ dispatch, commit }, value) {
    commit("replaceNaicsListForStrategy", value);
    await dispatch("generateStrategy");
  },
  async setNaicsListForStrategy({ dispatch, commit }, value) {
    commit("setNaicsListForStrategy", value);
    await dispatch("generateStrategy");
  },
  resetNaicsListForStrategy({ commit }) {
    commit("resetNaicsListForStrategy");
  },
  setSelectedPreviewSector({ commit }, value) {
    commit("setSelectedPreviewSector", value);
  },
  resetSelectedPreviewSector({ commit }) {
    commit("setSelectedPreviewSector", null);
  },
  setselectedSectorsPartB({ commit }, value) {
    commit("setselectedSectorsPartB", value);
  },
  replaceSelectedSectorsPartB({ commit }, value) {
    commit("replaceSelectedSectorsPartB", value);
  },
  resetSectorsPartB({ commit }) {
    commit("resetSectorsPartB");
  },
  resetToggles({ commit }) {
    commit("resetToggles");
  },
  setToggles({ commit }, value) {
    commit("setToggles", value);
  },
  replaceToggles({ commit }, value) {
    commit("replaceToggles", value);
  },
  setAllToggles({ commit }) {
    commit("setAllToggles");
  },
  setHoveredIndustry({ commit }, value) {
    commit("setHoveredIndustry", value);
  },
  setHoveredIndustryTable({ commit }, value) {
    commit("setHoveredIndustryTable", value);
  },
  async generateStrategy({ dispatch, state }) {
    dispatch("setStrategyShareLink", null);

    if (!state.naicsListForStrategy.length) {
      dispatch("abortStrategyRequest");
      dispatch("resetStrategyDataset");
    } else {
      await dispatch("setCurrentStrategyNaics", state.naicsListForStrategy);
      let query =
        state.currentMSA.msa + "/" + state.naicsListForStrategy.join(",");
      if (state.naicsListForStrategy.length > 1) {
        dispatch("getStrategyDataset", query);
      } else {
        let singleQuery =
          state.currentMSA.msa + "/" + state.naicsListForStrategy[0];
        dispatch("getSingleIndustryStrategyDataset", singleQuery);
      }
    }
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
