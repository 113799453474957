/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'info': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<path pid="0" d="M9.545 12.745l-.112.478a9.252 9.252 0 01-1.097.38 2.738 2.738 0 01-.702.112c-.399 0-.698-.102-.897-.306-.199-.209-.298-.465-.298-.769 0-.115.01-.234.03-.359.02-.124.052-.28.097-.47l.791-3.174c.04-.174.077-.36.112-.56.04-.2.06-.368.06-.508 0-.279-.047-.463-.142-.552-.09-.095-.276-.142-.56-.142-.11 0-.256.017-.44.052-.18.035-.317.065-.411.09l.112-.478a7.341 7.341 0 011.045-.389c.309-.084.553-.127.732-.127.408 0 .705.097.889.292.184.194.276.455.276.784a3.797 3.797 0 01-.12.829l-.799 3.174c-.05.194-.094.385-.134.575a2.546 2.546 0 00-.052.455c0 .284.062.478.186.583.13.104.337.156.62.156.095 0 .23-.012.404-.037.179-.025.316-.055.41-.09zm.53-9.545c0 .284-.092.533-.276.747a.879.879 0 01-.687.314.866.866 0 01-.657-.299 1.009 1.009 0 01-.276-.694c0-.274.092-.513.276-.717a.857.857 0 01.657-.307c.284 0 .515.097.695.292.179.189.269.41.269.664z" _fill="#353535"/>'
  }
})
