/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'y-axes': {
    width: 17,
    height: 17,
    viewBox: '0 0 17 17',
    data: '<path pid="0" d="M9.367 9.138l1.29-4.3h1.517l-2.52 7.3c-.386 1.067-1.043 1.6-1.968 1.6A2.55 2.55 0 017 13.633V12.53l.27.018c.359 0 .628-.067.808-.2.184-.129.328-.347.434-.656l.205-.545-2.227-6.31h1.535l1.342 4.3z" _fill="#353535"/><path pid="1" fill-rule="evenodd" clip-rule="evenodd" d="M14.86 12.3a.498.498 0 00-.707 0 .502.502 0 000 .709l.71.712H2.536a.5.5 0 000 1h12.243l-.625.626a.502.502 0 000 .708.498.498 0 00.707 0l1.518-1.523a.502.502 0 000-.708L14.86 12.3z" _fill="#CCC"/><path pid="2" fill-rule="evenodd" clip-rule="evenodd" d="M.622 1.823a.502.502 0 000 .708.498.498 0 00.706 0l.71-.712v12.366a.5.5 0 101 0V1.908l.621.623a.498.498 0 00.707 0 .502.502 0 000-.708L2.846.299a.498.498 0 00-.705 0L.62 1.823z" _fill="#353535"/>'
  }
})
