/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'expected': {
    width: 22,
    height: 7,
    viewBox: '0 0 22 7',
    data: '<path pid="0" d="M1 6l5-5 5 5 5-5 5 5" _stroke="#8D8D8D" stroke-dasharray="2 2"/>'
  }
})
