/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'sort': {
    width: 11,
    height: 9,
    viewBox: '0 0 11 9',
    data: '<path pid="0" d="M2.843.673a.355.355 0 00-.71 0V6.91l-.812-.813a.355.355 0 10-.502.502l1.423 1.423a.353.353 0 00.497-.004l1.418-1.419a.355.355 0 00-.501-.502l-.813.813V.673zM5.43.423a.355.355 0 00.25.605h4.965a.355.355 0 100-.71H5.68a.355.355 0 00-.25.105zM5.43 2.55a.355.355 0 01.25-.103h3.547a.355.355 0 110 .71H5.68a.355.355 0 01-.25-.606zM5.43 4.679a.355.355 0 01.25-.104h2.128a.355.355 0 110 .709H5.68a.355.355 0 01-.25-.605zM5.43 6.806a.355.355 0 01.25-.103h.71a.355.355 0 110 .709h-.71a.355.355 0 01-.25-.606z" _fill="#353535"/>'
  }
})
