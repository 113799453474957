import Vue from "vue";
import Notifications from "vue-notification";
import App from "./App.vue";
import Router from "vue-router";
import router from "./router";
import store from "./store";
import VueScrollTo from "vue-scrollto";
import VueSVGIcon from "vue-svgicon";
import tvaMq from "./utils/tvaMq";
import VueYoutube from "vue-youtube";

import "vue-svgicon/dist/polyfill";
import "@/compiled-icons";

Vue.config.productionTip = false;

Vue.prototype.$baseUrl = process.env.BASE_URL;

Vue.prototype.$scrollToTop = () =>
  window.scrollTo({
    top: 0,
    left: 0,
    behavior: "smooth",
  });
Vue.use(tvaMq, {
  mobile: 1024,
  tablet: 1024,
  desktop: Infinity,
  large: Infinity,
});
Vue.use(Router);
Vue.use(Notifications);
Vue.use(VueSVGIcon);
Vue.use(VueScrollTo, {
  container: "body",
  duration: 300,
  easing: "ease",
  offset: -170,
});
Vue.use(VueYoutube);

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount("#app");
