/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'search': {
    width: 14,
    height: 13,
    viewBox: '0 0 14 13',
    data: '<path pid="0" d="M7.129 9.17a2.5 2.5 0 100-5 2.5 2.5 0 000 5z" _fill="#000"/><path pid="1" d="M7.754 1.713V.42h-1.25v1.293a5.006 5.006 0 00-4.332 4.332H.879v1.25h1.293a5.005 5.005 0 004.332 4.332v1.293h1.25v-1.293a5.005 5.005 0 004.332-4.332h1.293v-1.25h-1.293a5.005 5.005 0 00-4.332-4.332zm-.625 8.707a3.754 3.754 0 01-3.75-3.75 3.754 3.754 0 013.75-3.75 3.754 3.754 0 013.75 3.75 3.754 3.754 0 01-3.75 3.75z" _fill="#000"/>'
  }
})
