import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

export default new Router({
  // mode: "history",
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes: [
    { path: "/", redirect: "/city-outlook" },
    {
      path: "/landing-mobility-pathways",
      name: "landing-mobility-pathways",
      component: () => import("./views/PartALanding.vue"),
    },
    {
      path: "/mobility-pathways",
      name: "mobility-pathways",
      component: () => import("./views/PartA.vue"),
    },
    {
      path: "/city-outlook",
      name: "city-outlook",
      component: () => import("./views/CityOutlook.vue"),
    },
    {
      path: "/industry-outlook",
      name: "industry-outlook",
      component: () => import("./views/IndustryWIP.vue"),
    },
    {
      path: "/about",
      name: "about",
      component: () => import("./views/About.vue"),
    },
    {
      path: "/tutorial",
      name: "tutorial",
      component: () => import("./views/Tutorial.vue"),
    },
  ],
});
