import Vuex from "vuex";
import Vue from "vue";
import app from "./modules/app";
import occupations from "./modules/occupations";
import cities from "./modules/cities";
import sectors from "./modules/sectors";
import charts from "./modules/charts";
import interactions from "./modules/interactions";
import citiesOutlook from "./modules/citiesOutlook";
import industryOutlook from "./modules/industryOutlook";
import strategy from "./modules/strategy";
Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    app,
    occupations,
    cities,
    sectors,
    charts,
    citiesOutlook,
    interactions,
    industryOutlook,
    strategy,
  },
});
