/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'arrow-down': {
    width: 12,
    height: 8,
    viewBox: '0 0 12 8',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M5.875 7.5a.177.177 0 00.25 0l5.687-5.687a.619.619 0 10-.874-.875L6.053 5.821a.077.077 0 01-.108 0L1.063.938a.619.619 0 10-.875.875L5.875 7.5z" _fill="#353535"/>'
  }
})
