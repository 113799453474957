import axios from "axios";
import { baseDomain,  citiesEndpoint, occupationEndpoint, citiesTop5IndustriesEndpoint } from '../../api/brookingsApi';

const state = {
    cities: null,
    citiesAreLoading: false,
    currentCity: null,
    cityOccupations: null,
    cityOccupationsIsPending: true,
    cityTop5Industries: null,
};

const getters = {
    cities: state => state.cities,
    currentCity: state => state.currentCity,
    cityOccupations: state => state.cityOccupations,
    isCityOccupationLoading: state => state.cityOccupationsIsPending,
    cityTop5Industries: state => state.cityTop5Industries,
    citiesAreLoading: state => state.citiesAreLoading
};

const mutations = {
    setCities(state, value) {
        state.cities = value;
    },
    setCitiesAreLoading(state, value) {
        state.citiesAreLoading = value;
    },
    setCurrentCity(state, value) {
        state.currentCity = value;
    },
    setCityOccupations(state, value){
        state.cityOccupations = value;
    },
    setCityOccupationsIsPending(state, value) {
        state.cityOccupationsIsPending = value;
    },
    setCityTop5Industries(state, value) {
        state.cityTop5Industries = value;
    }
};

const actions = {
    async setCities({ commit }) {
        commit("setCitiesAreLoading", true);
        let cities = await axios.get(baseDomain + citiesEndpoint);
        commit("setCities", cities.data);
        commit("setCitiesAreLoading", false);
    },
    async setCurrentCity({ commit }, value) {
        commit("setCurrentCity", value);
    },
    async setCityOccupations({commit}, msa){
        commit("setCityOccupationsIsPending", true)
        let cityOccupations = await axios.get(baseDomain + occupationEndpoint + msa);
        commit("setCityOccupations", cityOccupations.data)
        commit("setCityOccupationsIsPending", false)
    },
    async setCityTop5Industries({commit}, queryObj){
        let cityTop5Industries = await axios.get(baseDomain + citiesTop5IndustriesEndpoint + queryObj.cityValue + '/' + queryObj.socxx, {
            params : {
                socxx__code_in: queryObj.relatedSocxxList.join(',')
            }
        });
        commit("setCityTop5Industries", cityTop5Industries.data) 
    }
};

export default {
    state,
    getters,
    actions,
    mutations
};
