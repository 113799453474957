import axios from "axios";
import { baseDomain, sectorListEndpoint, occupationsFromSectorEndpoint } from '../../api/brookingsApi';
import _ from "lodash";

const state = {
    sectors: null,
    sectorsArePending: true,
    sectorNaicsxxList: null,
    selectedSector: null,
    occupationsFromSector: null
};

const getters = {
    sectors: state => state.sectors,
    sectorsArePending: state => state.sectorsArePending,
    selectedSectors: state => state.selectedSector,
    sectorNaicsxxList: state => state.sectorNaicsxxList,
    occupationsFromSector: state => state.occupationsFromSector
};

const mutations = {
    setSectors(state, value) {
        state.sectors = value;
    },
    setSectorsArePending(state, value) {
        state.sectorsArePending = value
    },
    setSelectedSector(state, value) {
        state.selectedSector = value
    },
    setSectorNaicsxxList(state, value) {
        state.sectorNaicsxxList = value
    },
    setOccupationsFromSector(state, value) {
        state.occupationsFromSector = value
    },
    resetOccupationsFromSector(state) {
        state.occupationsFromSector = null
    },
    resetSelectedSector(state) {
        state.selectedSector = null
    }
};

const actions = {
    async setSectors({ commit }, value) {
        commit("setSectorsArePending", true);
        let sectors = await axios.get(baseDomain + sectorListEndpoint + value);
        commit("setSectors", sectors.data);
        commit("setSectorsArePending", false);
    },
    setSelectedSector({ commit }, value) {
        const sectorId = value.value;
        const sectorObjList = _.filter(state.sectors, function (
            o
        ) {
            return o.value === sectorId;
        });
        const sectorCodesArray = sectorObjList.map(e => e.naicsxx)
        commit("setSelectedSector", value);
        commit("setSectorNaicsxxList", sectorCodesArray)
    },
    async setOccupationsFromSector({ commit }) {
        let arrayOfNaicxx = state.sectorNaicsxxList.join(',');
        let occupations = await axios.get(baseDomain + occupationsFromSectorEndpoint, {
            params: {
                naicsxx__list: arrayOfNaicxx
            }
        });
        commit("setOccupationsFromSector", occupations.data);
    },
    resetSelectedSector({commit}) {
        commit("resetOccupationsFromSector");
        commit("resetSelectedSector");
        commit("setSectorNaicsxxList", null)
    }
};

export default {
    state,
    getters,
    actions,
    mutations
};
