/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'table': {
    width: 15,
    height: 16,
    viewBox: '0 0 15 16',
    data: '<g clip-path="url(#clip0)"><path pid="0" _stroke="#CCC" stroke-width=".5" d="M.77 7.304h13.298M.77 9.111h13.298M.77 10.918h13.298M.77 12.723h13.298"/><path pid="1" transform="rotate(90.009 .505 5.696) skewX(-.001)" _stroke="#CCC" stroke-width=".5" d="M0-.25h9.363"/><path pid="2" transform="rotate(90.009 3.328 8.518) skewX(-.001)" _stroke="#CCC" stroke-width=".5" d="M0-.25h9.363"/><path pid="3" _stroke="#353535" stroke-linecap="round" d="M.932 2.083h13.137v12.471H.932zM1.27 5.306h12.298"/></g><defs><clipPath id="clip0"><path pid="4" _fill="#fff" transform="translate(0 .819)" d="M0 0h15v15H0z"/></clipPath></defs>'
  }
})
