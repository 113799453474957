import axios from "axios";
import {
    baseDomain,
    industryEndpoint,
    migrationOccupationEndpoint,
    migrationIndustriesEndpoint,
    occupationEndpoint,
    occupationalCategoriesEndopoint
} from '../../api/brookingsApi';

const state = {
    occupations: [],
    industries: [],
    selectedOccupation: null,
    selectedIndustry: null,
    migrationOccupations: null,
    migrationIndustries: null,
    transition: 0,
    relatedJobsSocxx: null,
    occupationalCategories: null,
    selectedOccuaptionalCategory: null,
    migrationIndustriesIsLoading: true,
    migrationOccupationsIsLoading: true,
    industriesArePending: true,
    occupationsListisPending: true,
};

const getters = {
    occupations: state => state.occupations,
    industries: state => state.industries,
    occupationsListisPending: state => state.occupationsListisPending,
    selectedOccupation: state => state.selectedOccupation,
    migrationOccupations: state => state.migrationOccupations,
    migrationIndustries: state => state.migrationIndustries,
    migrationOccupationsIsPending: state => state.migrationOccupationsIsLoading,
    migrationIndustriessIsPending: state => state.migrationIndustriesIsLoading,
    getTransitionValue: state => state.transition,
    relatedJobsSocxx: state => state.relatedJobsSocxx,
    occupationalCategories: state => state.occupationalCategories,
    selectedOccuaptionalCategory: state => state.selectedOccuaptionalCategory,
    industriesArePending: state => state.industriesArePending
};

const mutations = {
    setOccupations(state, value) {
        state.occupations = value;
    },
    setIndustries(state, value) {
        state.industries = value;
    },
    setIndustriesAreLoading(state, value) {
        state.industriesArePending = value
    },
    setOccupationListIsPending(state, value) {
        state.occupationsListisPending = value
    },
    setSelectedOccupation(state, value) {
        state.selectedOccupation = value
    },
    setSelectedIndustry(state, value) {
        state.selectedIndustry = value
    },
    setmigrationOccupationsIsLoading(state, value) {
        state.migrationOccupationsIsLoading = value
    },
    setMigrationIndustriesIsLoading(state, value) {
        state.migrationIndustriesIsLoading = value
    },
    setMigrationIndustries(state, value){
        state.migrationIndustries = value
    },
    setMigrationOccupations(state, value) {
        state.migrationOccupations = value
    },
    setTransition(state, value) {
        state.transition = value
    },
    setRelatedJobsSocxx(state, value) {
        state.relatedJobsSocxx = value
    },
    setOccupationalCategories(state, value) {
        state.occupationalCategories = value
    },
    setSelectedOccupationalCategory(state, value) {
        state.selectedOccuaptionalCategory = value
    }
};

const actions = {
    async getOccupations({ commit }) {
        commit("setOccupationListIsPending", true)
        let result = await axios.get(baseDomain + occupationEndpoint);
        commit("setOccupations", result.data);
        commit("setOccupationListIsPending", false)
    },
    async getMigrationOccupations({ commit }, value) {
        commit("setmigrationOccupationsIsLoading", true)
        let result = await axios.get(baseDomain + migrationOccupationEndpoint + value);
        commit("setMigrationOccupations", result.data);
        commit("setmigrationOccupationsIsLoading", false)
    },
    async getMigrationIndustries({ commit }, value) {
        commit("setMigrationIndustriesIsLoading", true)
        let result = await axios.get(baseDomain + migrationIndustriesEndpoint + value);
        commit("setMigrationIndustries", result.data);
        commit("setMigrationIndustriesIsLoading", false)
    },
    async getIndustries({ commit }, value) {
        commit("setIndustriesAreLoading", true);
        let result = await axios.get(baseDomain + industryEndpoint + value);
        commit("setIndustries", result.data);
        commit("setIndustriesAreLoading", false);
    },
    async setOccupationalCategories({ commit }) {
        let result = await axios.get(baseDomain + occupationalCategoriesEndopoint);
        commit("setOccupationalCategories", result.data);
    },
    setSelectedOccupation({ commit }, value) {
        commit("setSelectedOccupation", value);
    },
    setSelectedIndustry({ commit }, value) {
        commit("setSelectedIndustry", value);
    },
    setTransition({ commit }, value){
        commit("setTransition", value);
    },
    setRelatedJobsSocxx({ commit }, value) {
        commit("setRelatedJobsSocxx", value);
    },
    setSelectedOccupationalCategory({ commit }, value) {
        commit("setSelectedOccupationalCategory", value)
    }
};

export default {
    state,
    getters,
    actions,
    mutations
};
