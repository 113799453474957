/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'close-modal': {
    width: 17,
    height: 17,
    viewBox: '0 0 17 17',
    data: '<path pid="0" d="M14.717 14.3L1.917 1.792M14.571 1.646l-12.508 12.8" _stroke="#fff" stroke-linecap="round" stroke-linejoin="round"/>'
  }
})
