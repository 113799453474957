import axios from "axios";
import { baseDomain, partCIndustryListEndpoint, partCIndustryOutlookEndpoint, partCKeyOccupations, partCOccupationByMSA } from '../../api/brookingsApi';

const state = {
    IndustryList: null,
    IndustryListIsLoading: false,
    currentIndustry: null,
    industryOverlook: null,
    industryOverlookIsLoading: false,
    keyOccupations: null,
    keyOccupationsAreLoading: false,
    localOccupations: null,
    localOccupationsAreLoading: false,
    selectedMSA: null
};

const getters = {
    IndustryList: state => state.IndustryList,
    IndustryListIsLoading: state => state.IndustryListIsLoading,
    getCurrentIndustry: state => state.currentIndustry,
    industryOverlook: state => state.industryOverlook,
    industryOverlookIsLoading: state => state.industryOverlookIsLoading,
    keyOccupations: state => state.keyOccupations,
    keyOccupationsAreLoading: state => state.keyOccupationsAreLoading,
    localOccupations: state => state.localOccupations,
    localOccupationsAreLoading: state => state.localOccupationsAreLoading,
    selectedMSA: state => state.selectedMSA
};

const mutations = {
    setIndustryList(state, value) {
        state.IndustryList = value;
    },
    setIndustryListIsLoading(state, value) {
        state.IndustryListIsLoading = value;
    },
    setCurrentIndustry(state, value) {
        state.currentIndustry = value;
    },
    setIndustryOverlook(state, value) {
        state.industryOverlook = value
    },
    setIndustryOverlookLoader(state, value) {
        state.industryOverlookIsLoading = value
    },
    setKeyOccupations(state, value) {
        state.keyOccupations = value
    },
    setKeyOccupationsLoader(state, value) {
        state.keyOccupationsAreLoading = value
    },
    setLocalOccupations(state, value) {
        state.localOccupations = value
    },
    setLocalOccupationsLoader(state, value) {
        state.localOccupationsAreLoading = value
    },
    setSelectedMSA(state, value) {
        state.selectedMSA = value
    }
};

const actions = {
    async setIndustryList({ commit }) {
        commit("setIndustryListIsLoading", true);
        let IndustryList = await axios.get(baseDomain + partCIndustryListEndpoint);
        commit("setIndustryList", IndustryList.data);
        commit("setIndustryListIsLoading", false);
    },
    async setIndustryOverlook({ commit }, naics) {
        commit("setIndustryOverlookLoader", true);
        let industryList = await axios.get(baseDomain + partCIndustryOutlookEndpoint + naics);
        commit("setIndustryOverlook", industryList.data);
        commit("setIndustryOverlookLoader", false);
    },
    async setKeyOccupations({ commit }, naics) {
        commit("setKeyOccupationsLoader", true);
        let keyOccupationsList = await axios.get(baseDomain + partCKeyOccupations + naics);
        commit("setKeyOccupations", keyOccupationsList.data);
        commit("setKeyOccupationsLoader", false);
    },
    async setLocalOccupations({ commit }, msa) {
        commit("setLocalOccupationsLoader", true);
        commit("setSelectedMSA", msa);
        let localOccupationsList = await axios.get(baseDomain + partCOccupationByMSA + msa.msa);
        commit("setLocalOccupations", localOccupationsList.data);
        commit("setLocalOccupationsLoader", false);
    },
    resetLocalOccupations({ commit }) {
        commit("setLocalOccupations", null);
        commit("setSelectedMSA", null);
    },
    resetIndustryOverlook({ commit }) {
        commit("setIndustryOverlook", null);
    },
    resetKeyOccupations({ commit }) {
        commit("setKeyOccupations", null);
    },
    async setCurrentIndustry({ commit }, value) {
        commit("setCurrentIndustry", value);
    }
};

export default {
    state,
    getters,
    actions,
    mutations
};
