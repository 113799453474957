const state = {
  mode: null, // null || 'preview' || 'planning'
};

const getters = {
  getMode: state => state.mode,
};

const mutations = {
  setMode(state, value) {
    state.mode = value;
  },
};

const actions = {
  setMode({ commit }, value) {
    commit("setMode", value);
  },
  resetMode({ commit }) {
    commit("setMode", null);
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
