import {
  baseDomain,
  strategyEndpoint,
  strategySingleIndustryEndpoint,
  strategyShareLinkEndpoint,
} from "../../api/brookingsApi";
import axios from "axios";

const state = {
  currentStrategyNaics: [],
  strategyDataset: null,
  strategyLoader: false,
  sharelinkLoader: false,
  strategyShareLink: null,
  SharedStrategyLoader: false,
  quadrant: [],
  strategyDotSize: 0,
  strategyDotFill: [true, false],
};

const getters = {
  strategyDataset: state => state.strategyDataset,
  strategyShareLink: state => {
    return state.strategyShareLink
      ? `${window.location.origin}/#/city-outlook?share=${state.strategyShareLink}`
      : null;
  },
  isStrategyLoading: state => state.strategyLoader,
  isShareLinkLoading: state => state.sharelinkLoader,
  currentStrategyNaics: state => state.currentStrategyNaics,
  quadrant: state => state.quadrant,
  strategyDotSize: state => state.strategyDotSize,
  strategyDotFill: state => state.strategyDotFill,
  isSharedStrategyLoading: state => state.SharedStrategyLoader,
};

const mutations = {
  setSharedStrategyLoader(state, value) {
    state.SharedStrategyLoader = value;
  },
  setStrategyDataset(state, value) {
    state.strategyDataset = value;
  },
  resetStrategyDataset(state) {
    state.currentStrategyNaics = [];
    state.strategyDataset = null;
    state.strategyLoader = false;
    state.sharelinkLoader = false;
    state.quadrant = [];
    state.strategyDotSize = 0;
    state.strategyDotFill = [true, false];
    state.strategyShareLink = null;
  },
  setStrategyLoader(state, value) {
    state.strategyLoader = value;
  },
  setStrategyShareLink(state, value) {
    state.strategyShareLink = value;
  },
  setCurrentStrategyNaics(state, value) {
    state.currentStrategyNaics = [];
    value.map(naics => state.currentStrategyNaics.push(naics));
  },
  setQuadrant(state, value) {
    if (state.quadrant.includes(value)) {
      const index = state.quadrant.indexOf(value);
      state.quadrant.splice(index, 1);
    } else {
      state.quadrant.push(value);
    }
  },
  resetQuadrant(state) {
    state.quadrant = [];
  },
  setStrategyDotSize(state, value) {
    state.strategyDotSize = value;
  },
  setStrategyDotFill(state, value) {
    if (state.strategyDotFill.includes(value)) {
      const index = state.strategyDotFill.indexOf(value);
      state.strategyDotFill.splice(index, 1);
    } else {
      state.strategyDotFill.push(value);
    }
  },
  setStrategyShareLinkLoader(state, value) {
    state.sharelinkLoader = value;
  },
};

let pendingStrategyRequest = null;

const actions = {
  async getStrategyDataset({ commit }, value) {
    commit("setStrategyLoader", true);
    console.log("fetching strategy");
    if (pendingStrategyRequest) {
      console.log("aborting request");
      pendingStrategyRequest.abort();
      pendingStrategyRequest = null;
    }

    const controller = new AbortController();
    pendingStrategyRequest = controller;

    let strategyDataset = await axios
      .get(baseDomain + strategyEndpoint + value, { signal: controller.signal })
      .catch(err => {
        if (axios.isCancel(err)) {
          console.log("request canceled");
        }
      });

    commit("setStrategyDataset", strategyDataset.data);
    commit("setStrategyLoader", false);
  },
  async getSingleIndustryStrategyDataset({ commit }, value) {
    commit("setStrategyLoader", true);

    if (pendingStrategyRequest) {
      console.log("aborting request");
      pendingStrategyRequest.abort();
      pendingStrategyRequest = null;
    }

    const controller = new AbortController();
    pendingStrategyRequest = controller;

    let strategyDataset = await axios
      .get(baseDomain + strategySingleIndustryEndpoint + value, {
        signal: controller.signal,
      })
      .catch(err => {
        console.log("failed strat request");
        if (axios.isCancel(err)) {
          console.log("request canceled");
        }
      });

    commit("setStrategyDataset", strategyDataset.data);
    commit("setStrategyLoader", false);
  },
  async createStratergySharingLink({ commit }, value) {
    const url = baseDomain + strategyShareLinkEndpoint;

    commit("setStrategyShareLinkLoader", true);
    const shareLinkRequest = await axios.post(url, value);
    commit("setStrategyShareLink", shareLinkRequest.data.key);
    commit("setStrategyShareLinkLoader", false);
  },
  abortStrategyRequest() {
    if (pendingStrategyRequest) {
      console.log("aborting request");
      pendingStrategyRequest.abort();
      pendingStrategyRequest = null;
    }
  },
  setStrategyShareLink({ commit }, value) {
    commit("setStrategyShareLink", value);
  },
  setCurrentStrategyNaics({ commit }, value) {
    commit("setCurrentStrategyNaics", value);
  },
  setQuadrant({ commit }, value) {
    commit("setQuadrant", value);
  },
  resetQuadrant({ commit }) {
    commit("resetQuadrant");
  },
  setStrategyDotSize({ commit }, value) {
    commit("setStrategyDotSize", value);
  },
  setStrategyDotFill({ commit }, value) {
    commit("setStrategyDotFill", value);
  },
  setSharedStrategyLoader({ commit }, value) {
    commit("setSharedStrategyLoader", value);
  },
  resetStrategyDataset({ commit }) {
    commit("resetStrategyDataset");
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
