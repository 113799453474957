/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'dots-size': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<circle pid="0" cx="7.858" cy="8.177" r="7" transform="rotate(-90 7.858 8.177)" _stroke="#353535" stroke-linecap="round"/><circle pid="1" cx="7.858" cy="9.479" transform="rotate(-90 7.858 9.479)" _stroke="#CCC" stroke-linecap="round" r="5.699"/><circle pid="2" cx="7.858" cy="11.141" r="4.036" transform="rotate(-90 7.858 11.14)" _stroke="#353535" stroke-linecap="round"/>'
  }
})
