/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'strategy': {
    width: 10,
    height: 10,
    viewBox: '0 0 10 10',
    data: '<path pid="0" d="M4.5 0v1.035A4.001 4.001 0 001.035 4.5H0v1h1.035A4.001 4.001 0 004.5 8.965V10h1V8.965A4.001 4.001 0 008.965 5.5H10v-1H8.965A4.001 4.001 0 005.5 1.035V0h-1zm0 2.04V3h1v-.955A3.007 3.007 0 017.96 4.5H7v1h.955A3.007 3.007 0 015.5 7.96V7h-1v.955A3.007 3.007 0 012.04 5.5H3v-1h-.955A3.007 3.007 0 014.5 2.04z" _fill="#007FFF"/>'
  }
})
