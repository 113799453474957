// If you are running a local backend, edit baseDomain removing the production endpoint and add the localhost endpoint

export const baseDomain = process.env.VUE_APP_API_URL || "https://wof-be-prod-thsl7.ondigitalocean.app";
//export const baseDomain = "https://wof-be-gn3mz.ondigitalocean.app"; //staging
//export const baseDomain = "http://localhost:3000";
export const occupationEndpoint = "/occupation/";
export const industryEndpoint = "/industry/";
export const migrationOccupationEndpoint = "/migration/occupation/";
export const migrationIndustriesEndpoint = "/migration/industry/";
export const citiesEndpoint = "/cities/";
export const citiesTop5IndustriesEndpoint = "/top5/occupation/";
export const sectorListEndpoint = "/sectors/";
export const occupationalCategoriesEndopoint = "/occupational-cateogories/";
export const occupationsFromSectorEndpoint = "/occupation-from-naicxx/";
export const partBMSAEndpoint = "/msa-part-b/";
export const partBIndustryEndpoint = "/industry-overview/";
export const partBSectors = "/sectors-part-b/";
export const partCIndustryListEndpoint = "/naics-part-b/";
export const partCIndustryOutlookEndpoint = "/industry-outlook/";
export const partCKeyOccupations = "/key-occupations/";
export const partCOccupationByMSA = "/msa-occupations/";
export const strategyEndpoint = "/strategy/";
export const strategySingleIndustryEndpoint = "/strategy-single-industry/";
export const strategyShareLinkEndpoint = "/strategy-sharelink";
export const contactEndpoint = "/contact";

export const getCityReportEndpoint = city =>
  `${
    process.env.VUE_APP_FILES_URL ||
    "https://brookings.sfo3.digitaloceanspaces.com"
  }/reports/${encodeURIComponent(city)}.pdf`;
