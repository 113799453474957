/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'q4': {
    width: 18,
    height: 18,
    viewBox: '0 0 18 18',
    data: '<path pid="0" d="M1 9h16.314M9.157.843v16.314" _stroke="#fff" stroke-linecap="round"/><circle pid="1" r="2.5" transform="matrix(-1 0 0 1 13.814 4.5)" _fill="#fff"/>'
  }
})
