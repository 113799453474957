/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'arrow-up': {
    width: 12,
    height: 8,
    viewBox: '0 0 12 8',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M6.125.5a.177.177 0 00-.25 0L.187 6.187a.619.619 0 10.875.875l4.884-4.883c.03-.03.078-.03.108 0l4.883 4.883a.619.619 0 10.876-.875L6.125.5z" _fill="#353535"/>'
  }
})
