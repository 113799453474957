/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'current-location': {
    width: 15,
    height: 16,
    viewBox: '0 0 15 16',
    data: '<path pid="0" d="M7.5 10.74a2.5 2.5 0 100-5 2.5 2.5 0 000 5z" _fill="#000"/><path pid="1" d="M8.125 3.284V1.99h-1.25v1.294a5.006 5.006 0 00-4.332 4.331H1.25v1.25h1.293a5.005 5.005 0 004.332 4.332v1.294h1.25v-1.294a5.004 5.004 0 004.332-4.332h1.293v-1.25h-1.293a5.005 5.005 0 00-4.332-4.331zM7.5 11.99a3.754 3.754 0 01-3.75-3.75A3.754 3.754 0 017.5 4.49a3.754 3.754 0 013.75 3.75 3.754 3.754 0 01-3.75 3.75z" _fill="#000"/>'
  }
})
